export interface IPageState {
    showFormDrawer: boolean,
    showFilterDrawer: boolean,
    showFormModal: boolean,
    showAlertFormDrawer:boolean
}

export enum IPageActionConstants {
    OPEN_FORM_MODAL = 'OPEN_FORM_MODAL',
    CLOSE_FORM_MODAL = 'CLOSE_FORM_MODAL',

    OPEN_FORM_DRAWER = 'OPEN_FORM_DRAWER',
    CLOSE_FORM_DRAWER = 'CLOSE_FORM_DRAWER',

    OPEN_ALERT_FORM_DRAWER = 'OPEN_ALERT_FORM_DRAWER',
    CLOSE_ALERT_FORM_DRAWER = 'CLOSE_ALERT_FORM_DRAWER',

    TOGGLE_FILTER_DRAWER = 'TOGGLE_FILTER_DRAWER',
    OPEN_FILTER_DRAWER = 'OPEN_FILTER_DRAWER',
    CLOSE_FILTER_DRAWER = 'CLOSE_FILTER_DRAWER',

    RESET_STATE_TO_DEFAULT = 'RESET_STATE_TO_DEFAULT',
}

const init: IPageState = {
    showAlertFormDrawer: false,
    showFormDrawer: false,
    showFilterDrawer: false,
    showFormModal: false
};

export function PageReducer(state: IPageState = init, action: any): IPageState {
    switch (action.type) {
        case IPageActionConstants.OPEN_FORM_DRAWER:
            return {...state, showFormDrawer: true};
        case IPageActionConstants.CLOSE_FORM_DRAWER:
            return {...state, showFormDrawer: false};

        case IPageActionConstants.OPEN_ALERT_FORM_DRAWER:
            return {...state, showAlertFormDrawer: true};
        case IPageActionConstants.CLOSE_ALERT_FORM_DRAWER:
            return {...state, showAlertFormDrawer: false};

        case IPageActionConstants.TOGGLE_FILTER_DRAWER:
            return {...state, showFilterDrawer: !state.showFilterDrawer};
        case IPageActionConstants.OPEN_FILTER_DRAWER:
            return {...state, showFilterDrawer: true};
        case IPageActionConstants.CLOSE_FILTER_DRAWER:
            return {...state, showFilterDrawer: false};

        case IPageActionConstants.OPEN_FORM_MODAL:
            return {...state, showFormModal: true};
        case IPageActionConstants.CLOSE_FORM_MODAL:
            return {...state, showFormModal: false};

        case IPageActionConstants.RESET_STATE_TO_DEFAULT:
            return {...state, ...init};
        default:
            return state;
    }
}

export class PageAction {
    static openFormDrawer = () => {
        return {type: IPageActionConstants.OPEN_FORM_DRAWER};
    };

    static closeFormDrawer = () => {
        return {type: IPageActionConstants.CLOSE_FORM_DRAWER};
    };

    static openAlertFormDrawer = () => {
        return {type: IPageActionConstants.OPEN_ALERT_FORM_DRAWER};
    };

    static closeAlertFormDrawer = () => {
        return {type: IPageActionConstants.CLOSE_ALERT_FORM_DRAWER};
    };

    static toggleFilterDrawer = () => {
        return {type: IPageActionConstants.TOGGLE_FILTER_DRAWER};
    };

    static openFilterDrawer = () => {
        return {type: IPageActionConstants.OPEN_FILTER_DRAWER};
    };

    static closeFilterDrawer = () => {
        return {type: IPageActionConstants.CLOSE_FILTER_DRAWER};
    };

    static openFormModal = () => {
        return {type: IPageActionConstants.OPEN_FORM_MODAL};
    };

    static closeFormModal = () => {
        return {type: IPageActionConstants.CLOSE_FORM_MODAL};
    };

    static resetStateToDefault = () => {
        return {type: IPageActionConstants.RESET_STATE_TO_DEFAULT};
    }
}