import {combineReducers, createStore, applyMiddleware} from 'redux';
import {IUiState, UiReducer} from "./UiState";
import {IPageState, PageReducer} from "./PageState";
import thunk from "redux-thunk";

export interface IRootState {
    ui: IUiState,
    page: IPageState,
}

const store = createStore<IRootState, any, any, any>(
    combineReducers({
        ui: UiReducer,
        page: PageReducer,
    }), applyMiddleware(thunk));

export default store;
