import React, {Component} from 'react';

class NotFound extends Component {
    render() {
        return (
            <div className="content-inner">
                <section>
                    <div className="container-fluid">
                        Page Not Found
                    </div>
                </section>
            </div>
        );
    }
}

export default NotFound;
