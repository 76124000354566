import React from 'react';
import store from "./store/AppStore";
import {Provider} from "react-redux";
import AppContainer from "./components/layouts/AppContainer";

import './assets/scss/style.scss';

function App() {
    return (
        <Provider store={store}>
            <AppContainer />
        </Provider>
    );
}

    // production
    //   ">0.2%",
    //   "not dead",
    //   "not op_mini all"

    // development
    // "last 1 chrome version",
    // "last 1 firefox version",
    // "last 1 safari version",

export default App;
